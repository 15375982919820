import { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import { useRouter, NextRouter } from "next/router";
// Components
import { NoHeader } from "../components/templates";
import { Button } from "../components/atoms";
// Configs
import { routesName } from "../configs";
// Utils
import { setRoute } from "../utils";

const Custom404: NextPage = () => {
  const router: NextRouter = useRouter();

  return (
    <NoHeader>
      {/* SEO */}
      <Head>
        <title>Foodizmart: Not Found</title>
      </Head>

      {/* Content */}
      <section className="d-flex flex-column align-items-center justify-content-center vw-100 vh-100">
        <div className="position-relative mb-4">
          <Image
            src="/icons/icon-404.svg"
            width={483}
            height={300}
          />
        </div>

        <div className="d-flex flex-column mb-4">
          <h2 className="text-4xl fw-bold text-center color-palette-1 mb-4">
            Oops! Not Found
          </h2>
          <p className="text-center color-palette-1 m-0 d-sm-block d-none">
            Halaman yang kamu cari sudah
            <br />
            tidak tersedia atau tidak ditemukan.
          </p>
        </div>

        <Button
          label="Kembali belanja"
          variant="danger"
          size="lg"
          buttonProps={{
            onClick: () => { router.back(); },
          }}
        />
      </section>
    </NoHeader>
  );
};

export default Custom404;
